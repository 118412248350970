const isProduction = process.env.NODE_ENV === "production";

class LoggerService {
    constructor() {
        this.log = this.log.bind(this);
    }

    log(message, ...optionalParams) {
        if (isProduction) {
            return;
        }
        console.log(message, ...optionalParams);
    }
}

const loggerServiceInstance = new LoggerService();
export default loggerServiceInstance;

// how to use:
// import loggerServiceInstance from "../utilities/loggerService";
// loggerServiceInstance.log("hello world");